import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import {courseDetails} from "../appConstants";
import authService from 'src/services/authService';
import { CONNECT_QA, SERVER_URL } from 'src/settings';


function GuestGuard({ children }) {
  const account = useSelector((state) => state.account);
  const urlParams = new URLSearchParams(window.location.search);
  const admin_token = urlParams.get('admin_token') || urlParams.get('t') || authService.getAccessToken();
  const certificate_id = urlParams.get('cert') || '';
  const cme_id = urlParams.get('cme') || '';
  const hash = urlParams.get('hash') || '';
  const destination = urlParams.get('destination');
  const [isurl, setIsurl]= useState(true)

  const isLogin = window.location.pathname === "/login"


  const removeRedirectSessionStorage = () => {
      sessionStorage.removeItem('redirectToSimulation');
      sessionStorage.removeItem('simulationSubpath');
      sessionStorage.removeItem('redirectToMarket');
      sessionStorage.removeItem('redirectToContent');
      sessionStorage.removeItem('redirectToRoot');
      sessionStorage.removeItem('redirectToCourses');
      sessionStorage.removeItem('redirectToJournal');
  }


  const valid_token = authService.isValidToken(admin_token)
  if(!valid_token){
    if(admin_token){
      localStorage.removeItem("accessToken")
    }
  }
  if(admin_token && valid_token){

    const path = SERVER_URL + 'login?admin_token=' + admin_token + (destination ? '?destination='+destination : '') + (certificate_id ? '?cert='+certificate_id : '') + (cme_id ? '?cme='+cme_id+'&hash='+hash : '')
    const path1 = CONNECT_QA + 'login?admin_token=' + admin_token + (destination ? '?destination='+destination : '') + (certificate_id ? '?cert='+certificate_id : '') + (cme_id ? '?cme='+cme_id+'&hash='+hash : '')

    const url=document.referrer
    const storedValue = localStorage.getItem('data');
    if(localStorage.getItem("reset")){
         if(localStorage.getItem("reset")){
          window.location.href = path+"&data=/settings&reset"
          localStorage.removeItem("reset")
          setIsurl(false)
         }else{
            window.location.href = path+(storedValue?"&data="+storedValue:'')
            setIsurl(false)
      }}else if(url=="https://qa.medtigo.com/"){
            window.location.href=path1+(storedValue?"&data="+storedValue:'')
            setIsurl(false)
          }else
          if(isurl){
          if(sessionStorage.getItem("redirectToMarket") && isLogin){
            removeRedirectSessionStorage()
              window.location.href = "https://medtigo.store?t="+account.user.wp_token+"&t1="+admin_token
            }else if (sessionStorage.getItem("redirectToSimulation") && isLogin){
              if(sessionStorage.getItem("simulationSubpath")){
                const subpath = sessionStorage.getItem("simulationSubpath")
                removeRedirectSessionStorage()
                window.location.href = "https://simulation.medtigo.com/"+ subpath +"&t="+account.user.wp_token+"&t1="+admin_token
              }else{
                removeRedirectSessionStorage()
                window.location.href = "https://simulation.medtigo.com?t="+account.user.wp_token+"&t1="+admin_token
              }
            } else if (sessionStorage.getItem("redirectToRoot") && isLogin){
            removeRedirectSessionStorage()
              window.location.href = "https://medtigo.com?t="+account.user.wp_token+"&t1="+admin_token
            } else if(sessionStorage.getItem("redirectToCourses") && isLogin){
              const coursesToken = sessionStorage.getItem('coursesToken');
              if(coursesToken){
                removeRedirectSessionStorage()
                window.location.href = "https://courses.medtigo.com?t="+account.user.wp_token+"&t1="+coursesToken
              }
            }
            else if (sessionStorage.getItem("redirectToJournal") && isLogin){
            removeRedirectSessionStorage()
              window.location.href = "https://journal.medtigo.com?t="+account.user.wp_token+"&t1="+admin_token
            }else{
              if(storedValue && isLogin){
                window.location.href = path+(storedValue?"&data="+storedValue:'') // to connect app
              }
              else if(account && account.user && account.user.wp_token && isLogin){
                window.location.href =  "https://medtigo.com?t="+account.user.wp_token+"&t1="+admin_token // to root site with loggin in
              }
              else if(isLogin){
                window.location.href = path // to connect app
              }else if(sessionStorage.getItem("apple-login")) {
                window.location.href = "https://medtigo.com?t="+account.user.wp_token+"&t1="+admin_token
              }
            }
          }

          if(isLogin){
            localStorage.removeItem('data')
          }
    
  }

  return children;
}

GuestGuard.propTypes = {
  children: PropTypes.any
};

export default GuestGuard;
