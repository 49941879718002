import React, { useState } from 'react';
import {Router} from 'react-router-dom';
import {createBrowserHistory} from 'history';
import SimpleReactLightbox from "simple-react-lightbox";
// import {create} from 'jss';
// import rtl from 'jss-rtl';
// import {ConfirmProvider} from 'material-ui-confirm';
// import MomentUtils from '@date-io/moment';
import {SnackbarProvider} from 'notistack';
import {
  createStyles,
 
  makeStyles,
  // StylesProvider,
  // ThemeProvider
} from '@material-ui/core';
// import {MuiPickersUtilsProvider} from '@material-ui/pickers';
import Auth from 'src/components/Auth';
// import GoogleAnalytics from 'src/components/GoogleAnalytics';
// import ScrollReset from 'src/components/ScrollReset';
// import useSettings from 'src/hooks/useSettings';
// import {createTheme} from 'src/theme';
import Routes from 'src/Routes';
import './styles.css';
import { gridColumnPositionsSelector } from '@mui/x-data-grid';
import CookieBot from 'react-cookiebot';
// import {CertificatesContextProvider} from './context/CertificatesContext';
// import {LearningContextProvider} from './context/LearningContext';
// import {StaffingContextProvider} from "./context/StaffingContext";
const domainGroupId = 'f8b0eaba-650c-4c89-8620-379b37037978';
const history = createBrowserHistory();
// const jss = create({plugins: [...jssPreset().plugins, rtl()]});
// const url=window.parent.location
// const urll= document.referrer
// console.log(url)
// var lastUrl = document.URL;
// console.log(lastUrl, "============")
// console.log(urll)
// var yut= window.history.previous.href
// console.log(yut, "--=-=-=-==--")

const useStyles = makeStyles(() => createStyles({
  '@global': {
    '*': {
      boxSizing: 'border-box',
      margin: 0,
      padding: 0,
    },
    '.SRLPanzoomImage': {
      width: '40% !important',
    },
    '#SRLLightbox': {
      backgroundColor: 'rgba(111, 105, 105, 0.95)',
    },
    'iframe#launcher': {
      zIndex: '100 !important',
    },
    html: {
      '-webkit-font-smoothing': 'antialiased',
      '-moz-osx-font-smoothing': 'grayscale',
      height: '100%',
      width: '100%'
    },
    body: {
      height: '100%',
      width: '100%'
    },
    '#root': {
      height: '100%',
      width: '100%'
    },
    '.MuiPickersCalendarHeader-transitionContainer': {
      cursor: 'pointer',
    },
    '.MuiPickersDatePickerRoot-toolbar': {
      display: 'none !important',
    },
  }
}));

function App() {
  useStyles();
  const [ok, _setOk] = React.useState(true)
  const [hasCookieBot, setHasCookieBot] = useState(undefined);
  const urlParams = new URLSearchParams(window.location.search);
  // const {settings} = useSettings();

  React.useEffect(() => {
    if (window.location.href.includes('path=simulation')) {
      if(urlParams.get("subpath")){
        sessionStorage.setItem('simulationSubpath', urlParams.get("subpath"));
      }
      localStorage.removeItem('accessToken')
      sessionStorage.setItem('redirectToSimulation', true);
    }else if (window.location.href.includes('path=medtigoStore')) {
      localStorage.removeItem('accessToken')
      sessionStorage.setItem('redirectToMarket', true);
    }else if (window.location.href.includes('path=content')) {
      localStorage.removeItem('accessToken')
      sessionStorage.setItem('redirectToContent', true);
    }else if (window.location.href.includes('path=changePassword')) {
      localStorage.setItem('reset', true);
    }else if (window.location.href.includes('path=medtigoRoot')) {
      localStorage.removeItem('accessToken')
      sessionStorage.setItem('redirectToRoot', true);
    }else if(window.location.href.includes('path=medtigoCourses')){
      localStorage.removeItem('accessToken')
      sessionStorage.setItem('redirectToCourses', true);
    }else if (window.location.href.includes('path=medtigoJournal')) {
      localStorage.removeItem('accessToken')
      sessionStorage.setItem('redirectToJournal', true);
    }
    else{
      if(localStorage.getItem('reset')){
        localStorage.removeItem("reset")
      }
    }
    
  }, []);


  React.useEffect(() => {
    try {
      const whitelisted = ["medtigo", "localhost","auth.medtigo.com","auth-qa.medtigo.com"]
      // we have to check the current domain
      if (whitelisted.includes(window.parent.location.hostname) || whitelisted.includes(window.parent.location.hostname.split(".")[1]) ) {
        // if the domain is correct we are good to go
        return _setOk(false);
      }} catch (err) {
      // otherwise ACCESS DENIED"
      return;
    }}, [])
  
if(ok){
  return <h1>ACCESS DENIED</h1>;
}
else{
  return (
    <SimpleReactLightbox>
      {/* <CookieBot domainGroupId={domainGroupId} /> */}
              <SnackbarProvider maxSnack={1}>
                <Router history={history}>
                  <Auth>
                          <Routes/>
                  </Auth>
                </Router>
              </SnackbarProvider>
           
    </SimpleReactLightbox>
  );
}
}

export default App;
